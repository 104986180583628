import apiInstance from './apiInstance';

async function createStore(storeData = {}) {
	try {
		const response = await apiInstance.post('/stores/create_store/', storeData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to create store."
		};
	}
}

async function viewStore() {
	try {
		const response = await apiInstance.get('/stores/view_store/');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view store."
		};
	}
}

async function updateStore(updatedData = {}) {
	try {
		const response = await apiInstance.put('/stores/update_store/', updatedData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to update store."
		};
	}
}

async function deleteStore() {
	try {
		const response = await apiInstance.delete('/stores/delete_store/');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to delete store."
		};
	}
}

export {
	createStore,
	viewStore,
	updateStore,
	deleteStore
};