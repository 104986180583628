import './App.css';
import React, { useState } from 'react';
import Layout from './components/Layout';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { LocaleProvider } from './context/LocaleContext';
import { RedirectionProvider } from './context/RedirectionContext';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import CssBaseline from '@mui/material/CssBaseline';
import AuthMiddleware from './pages/AuthMiddleware';
import { protectedRoutes, publicRoutes } from './routes';
import Background from './components/Background';

function App() {
	return (
		<LocaleProvider>
			<BrowserRouter>
				<ThemeProvider>
					<Background>
						<AuthProvider>
							<RedirectionProvider>
								<CssBaseline />
								<Routes>
									{protectedRoutes.map(route =>
										['/onboarding', '/create-store'].includes(route.path) ?
											<Route key={route.path} path={route.path} element={<AuthMiddleware type="initial">{route.component}</AuthMiddleware>} />
											:
											<Route key={route.path} path={route.path} element={<AuthMiddleware><Layout>{route.component}</Layout></AuthMiddleware>} />
									)}
									{publicRoutes.map(route =>
										<Route key={route.path} path={route.path} element={<Layout>{route.component}</Layout>} />
									)}
									<Route path="*" element={<Navigate replace to="/dashboard" />} />
								</Routes>
							</RedirectionProvider>
						</AuthProvider>
					</Background>
				</ThemeProvider>
			</BrowserRouter>
		</LocaleProvider>
	);
}

export default App;
