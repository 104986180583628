import apiInstance from './apiInstance';

async function getStockStatistics() {
	try {
		const response = await apiInstance.get('/stocks/view_stock_statistics');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch stock statistics."
		};
	}
}

async function getItemStatistics(itemId, lastNDays = 200) {
	try {
		const response = await apiInstance.get('/items/view_item_statistics', {
			params: {
				item_id: itemId,
				last_n_days: lastNDays
			}
		});
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch item statistics."
		};
	}
}

async function getHomeStatistics() {
	try {
		const response = await apiInstance.get('/home/home_insight');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch home statistics."
		};
	}
}

export { getStockStatistics, getItemStatistics, getHomeStatistics };