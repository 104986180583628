import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, ClickAwayListener, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

export default function TextWithInfo({ text, info, textProps, infoProps }) {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					onClose={handleTooltipClose}
					open={open}
					placement="top"
					disableFocusListener
					disableHoverListener
					disableTouchListener
					arrow
					title={info}
					slotProps={{
						popper: {
							disablePortal: true,
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, -14],
									},
								},
							],
						},
					}}
				>
					<Button
						disableRipple
						endIcon={<InfoOutlined {...infoProps} />}
						sx={{
							display: 'flex',
							alignItems: 'center',
							textTransform: 'none',
							p: 0.2,
							'& .MuiButton-icon': {
								ml: 0.5,
								'& > *:first-of-type': {
									fontSize: '0.8rem',
								}
							}
						}}
						onClick={handleTooltipOpen}
					>
						<Typography {...textProps}>{text}</Typography>
					</Button>
				</Tooltip>
			</div>
		</ClickAwayListener>
	);
}