import apiInstance from './apiInstance';

async function addInstantSellTransaction(items = []) {
	try {
		const response = await apiInstance.post('/instant_sell/add_instant_sell_transaction', items);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to add instant sell transaction."
		};
	}
}

async function updateInstantSellTransaction(transactionId, updatedData = {}) {
	if (!transactionId) {
		return { success: false, message: "Transaction ID is required." };
	}

	try {
		const response = await apiInstance.put(
			`/instant_sell/update_instant_sell_transaction/${transactionId}`,
			updatedData
		);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to update instant sell transaction."
		};
	}
}

async function viewInstantSellTransaction(options = {}) {
	try {
		const response = await apiInstance.get('/instant_sell/view_instant_sale_transaction', {
			params: options
		});
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view instant sell transactions."
		};
	}
}

export {
	addInstantSellTransaction,
	updateInstantSellTransaction,
	viewInstantSellTransaction,
};