import React from "react";
import { useTheme } from "../context/ThemeContext";
import { Box, Typography } from "@mui/material";
import krayaLogoWhite from '../assets/krayaLogoWhite.jpg'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const loaderAnimation = keyframes`
	0%     {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
	16.67% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
	33.33% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
	50%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
	66.67% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
	83.33% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
	100%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
`
const Loader = styled.div`
	height: 30px;
	aspect-ratio: 2.5;
	--_g: no-repeat radial-gradient(farthest-side,${props => props.color} 90%,#0000);
	background: var(--_g), var(--_g), var(--_g), var(--_g);
	background-size: 20% 50%;
	animation: ${loaderAnimation} 1s infinite linear;
`

const Loading = () => {
	const { themeObj } = useTheme();

	return (
		<Box sx={{ position: "relative", display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: themeObj.background, overflowX: "hidden", color: themeObj.white }}>
			<Box component='main' sx={{ flexGrow: 1, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", overflow: "hidden" }}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem', flexDirection: "column" }}>
					<img src={krayaLogoWhite} width={100} alt="Kraya Logo" style={{ borderRadius: '50%' }} />
					{/* <Typography color={themeObj.title} fontSize="1.5rem" fontWeight={600}>Loading, Please Wait</Typography> */}
					<Loader color={themeObj.primary} />
				</Box>
			</Box>
		</Box>
	);
};

export default Loading;