import React, { useState } from "react";
import { Box, Paper, Typography, Stack, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip, Grid } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { updateTicket } from "../../apis/ticketApi";

export default function TicketItem(props) {
	const { themeObj } = useTheme();
	const [open, setOpen] = useState(false);

	const markAsResolved = async () => {
		const response = await updateTicket(props.ticket_id, {
			ticket_category: props.ticket_category,
			ticket_description: props.ticket_description,
			ticket_status: 'resolved',
		});

		if (response.success) {
			props.updateTicketStatus(props.ticket_id, 'resolved');
		}
	}

	const markAsUnresolved = async () => {
		const response = await updateTicket(props.ticket_id, {
			ticket_category: props.ticket_category,
			ticket_description: props.ticket_description,
			ticket_status: 'unresolved',
		});

		if (response.success) {
			props.updateTicketStatus(props.ticket_id, 'unresolved');
		}
	}

	return (
		<Box sx={{ marginBottom: props.margin || "10px" }}>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				fullWidth
				PaperProps={{
					sx: {
						backgroundColor: themeObj.secondary,
					}
				}}
			>
				<DialogContent sx={{ pb: 0 }}>
					<Stack>
						<Typography color={themeObj.title} fontSize="1.5rem">Issue Details</Typography>
						<Typography color={themeObj.subtitle}>ID: {props.ticket_id}</Typography>
					</Stack>
				</DialogContent>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Stack>
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Description</Typography>
								<Typography color={themeObj.title}>{props.ticket_description}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack sx={{ width: "fit-content" }} gap={0.5}>
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Category</Typography>
								<Chip sx={{ textTransform: "capitalize" }} size="small" label={props.ticket_category} color="primary" />
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack sx={{ width: "fit-content" }} gap={0.5}>
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Status</Typography>
								<Chip sx={{ textTransform: "capitalize" }} size="small" label={props.ticket_status} color={props.ticket_status === 'resolved' ? 'success' : 'error'} />
							</Stack>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{props.ticket_status === 'unresolved' ?
						<Button
							fullWidth
							onClick={markAsResolved}
							sx={{
								backgroundColor: themeObj.primary,
								color: themeObj.title,
								"&:hover": {
									backgroundColor: themeObj.primary_hover,
								}
							}}
						>
							Mark as Resolved
						</Button>
						:
						<Button
							fullWidth
							onClick={markAsUnresolved}
							sx={{
								backgroundColor: themeObj.primary,
								color: themeObj.title,
								"&:hover": {
									backgroundColor: themeObj.primary_hover,
								}
							}}
						>
							Mark as Unresolved
						</Button>
					}
				</DialogActions>
			</Dialog>
			<Button variant="text" sx={{ color: 'revert', textTransform: 'none', width: '100%', padding: 0, borderRadius: '5px', border: 'none' }} onClick={() => setOpen(true)}>
				<Paper elevation={3} sx={{ width: '100%', padding: '15px 20px', backgroundColor: themeObj.secondary }}>
					<Stack flexDirection="column" justifyContent="space-between" sx={{ height: '100%' }}>
						<Stack flexDirection="row" justifyContent="space-between" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
							<Typography noWrap textAlign="left" fontSize="1.2rem" color={themeObj.title}>{props.ticket_id}</Typography>
							<Chip sx={{ textTransform: "capitalize" }} size="small" label={props.ticket_status} color={props.ticket_status === 'resolved' ? 'success' : 'error'} />
						</Stack>
						<Stack flexDirection="row" justifyContent="space-between" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
							<Chip sx={{ textTransform: "capitalize" }} size="small" label={props.ticket_category} color="primary" />
							{/* <Typography noWrap fontSize="1rem" color={themeObj.subtitle}>{props.ticket_category}</Typography> */}
						</Stack>
					</Stack>
				</Paper>
			</Button>
		</Box>
	)
}