import apiInstance from './apiInstance';

async function searchItems(options) {
	const params = {
		query: options.query || '*',
		global_search: options.globalSearch || true,
		page: options.page || 1,
		per_page: options.perPage || 20,
	};
	if (options.categories) params.categories = options.categories.join(",");
	if (options.barcodeId) params.barcode_id = options.barcodeId;
	if (options.sortBy) params.sort_by = options.sortBy;

	try {
		const response = await apiInstance.get('/items/search_items', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to search items."
		};
	}
}

async function addItems(items) {
	try {
		const response = await apiInstance.post('/items/add_items/', items);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to add items."
		};
	}
}

async function updateItem(itemId, updatedData) {
	try {
		const response = await apiInstance.put(`/items/update_item/${itemId}`, updatedData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to update item."
		};
	}
}

async function deleteItem(itemId) {
	try {
		const response = await apiInstance.delete(`/items/delete_item/${itemId}`);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to delete item."
		};
	}
}

export { addItems, updateItem, deleteItem, searchItems };