import axios from "axios";

const apiInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Add a request interceptor
apiInstance.interceptors.request.use(
	function (config) {
		// Retrieve authUser data from localStorage
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			config.headers.Authorization = "Bearer " + accessToken;
		}
		// config.headers.Authorization = `Bearer 22335564899`;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default apiInstance;