import apiInstance from './apiInstance';

async function createSale(transactionData) {
	try {
		const response = await apiInstance.post('/transactions/create_sale', transactionData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to create sale."
		};
	}
}

async function createPurchase(transactionData) {
	try {
		const response = await apiInstance.post('/transactions/create_purchase', transactionData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to create purchase."
		};
	}
}

async function viewTransactions(options = {}) {
	const params = {
		page: options.page || 1,
		page_size: options.pageSize || 30,
	};

	if (options.transactionId) params.transaction_id = options.transactionId;
	if (options.itemId) params.item_id = options.itemId;
	if (options.barcodeId) params.barcode_id = options.barcodeId;
	if (options.customerEmail) params.customer_email = options.customerEmail;
	if (options.startDate) params.start_date = options.startDate;
	if (options.endDate) params.end_date = options.endDate;
	if (options.itemCategory) params.item_category = options.itemCategory;
	if (options.type) params.transaction_type = options.type;
	if (options.paymentMethod) params.payment_method = options.paymentMethod;
	if (options.totalAmountMin) params.total_amount_min = options.totalAmountMin;
	if (options.totalAmountMax) params.total_amount_max = options.totalAmountMax;
	if (options.sortBy) params.sort_by = options.sortBy;
	if (options.sortOrder) params.sort_order = options.sortOrder;

	try {
		const response = await apiInstance.get('/transactions/view_transactions', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view transactions."
		};
	}
}

async function viewTransactionBill(transactionId) {
	const params = { transaction_id: transactionId };

	try {
		const response = await apiInstance.get('/transactions/view_transaction_bill/', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch bill."
		};
	}
}

export { viewTransactions, createPurchase, createSale, viewTransactionBill };