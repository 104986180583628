import React from "react";
import { IconButton, Stack, Box, Typography, Paper, Chip, Button, Grid, Divider } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { KeyboardBackspaceRounded } from "@mui/icons-material";

export default function TicketInterface({ onBack, data }) {
	const { themeObj } = useTheme();

	return (
		<Grid container spacing={1.5}>
			<Grid item xs={12}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<IconButton onClick={onBack}>
						<KeyboardBackspaceRounded htmlColor={themeObj.primary} />
					</IconButton>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
					<Stack gap={1} sx={{ maxWidth: "100%" }}>
						<Stack direction="row" gap={1} alignItems="center">
							<Typography sx={{ textOverflow: "ellipsis", textWrap: "nowrap", overflow: "hidden" }} color={themeObj.title} fontSize="1.1rem"></Typography>
						</Stack>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ height: "20px" }} />
			</Grid>
		</Grid>
	);
}