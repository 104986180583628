import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import Loading from "../components/Loading";

const AuthMiddleware = ({ children, type }) => {
	const { mongoUser, user, store, loaded, refresh } = useAuth();

	useEffect(() => {
		if (user) return refresh();
	}, [user]);
	// useEffect(() => {
	// 	if (type === 'initial' && !user) {
	// 		navigate('/signin');
	// 	}
	// 	else if (type !== 'initial' && !mongoUser) {
	// 		navigate('/onboarding');
	// 	}
	// 	else if (type !== 'initial' && !store) {
	// 		navigate('/create-store');
	// 	}
	// }, []);

	// return children;
	if (!loaded) return <Loading />;
	
	if (!user) {
		return <Navigate to="/signin" replace />;
	}

	// If not initial route and no MongoDB user, redirect to onboarding
	if (type !== 'initial' && !mongoUser) {
		return <Navigate to="/onboarding" replace />;
	}

	// If not initial route and no store, redirect to create-store
	if (type !== 'initial' && !store) {
		return <Navigate to="/create-store" replace />;
	}

	// If all checks pass, render children
	return children;
};

export default AuthMiddleware;
