import React, { useState } from "react";
import { Box, Paper, Typography, Stack, Grid } from "@mui/material";
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import { useTheme } from "../../context/ThemeContext";
import CustomButton from "../Base/CustomButton";

function formatPrice(price) {
	if (price >= 10000000) {
		return (price / 10000000).toFixed(2) + ' Cr';
	} else if (price >= 100000) {
		return (price / 100000).toFixed(2) + ' Lacs';
	} else if (price >= 1000) {
		return (price / 1000).toFixed(2) + 'k';
	}
	return price;
}

export default function ItemItem(props) {
	const { themeObj } = useTheme();

	return (
		<Box sx={{ marginBottom: '12px', position: 'relative' }}>
			<Paper elevation={3} sx={{ width: '100%', padding: '10px 20px', backgroundColor: themeObj.secondary }}>
				<Grid container alignItems='center' spacing={1}>
					<Grid item xs={12}>
						<Stack direction='row' justifyContent='space-between' alignItems='center'>
							<Typography sx={{ overflow: "hidden", maxHeight: "50px", textWrap: "wrap", textOverflow: "ellipsis", maxWidth: "85%" }} width='fit-content' textAlign="left" fontSize="1rem" color={themeObj.title}>{props.item_name}</Typography>
							<Typography noWrap fontSize="0.9rem" color={themeObj.subtitle}>₹ {formatPrice(props.item_details.selling_price)}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<CustomButton
							sx={{
								backgroundColor: themeObj.chip.yellow_background,
								border: "none",
								color: themeObj.chip.yellow,
								'&:hover': {
									backgroundColor: themeObj.chip.yellow_background,
									border: "none"
								},
								'&:focus': {
									backgroundColor: themeObj.chip.yellow_background,
									border: "none"
								}
							}}
							fullWidth
							variant="outlined"
							onClick={() => props.addToCart()}
							startIcon={<AddTaskRoundedIcon fontSize="large" />}
						>
							Add to Cart
						</CustomButton>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	)
}