import React, { useReducer, useRef } from "react";
import { Button, Divider, Stack, Typography } from '@mui/material'
import AddItemForm from "../components/AddItem/AddItemForm";
import { useTheme } from "../context/ThemeContext";
import { toast } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import BarcodeScanner from "../components/Common/BarcodeScanner";
import { addItems } from "../apis/itemApi";

const formArrayReducer = (state, action) => {
	switch (action.type) {
		case "ADD_ENTRY":
			return !state.filter(form => form.id === action.payload.id).length ? [{ id: action.payload.id, values: { ...action.payload.values } }, ...state] : state;
		case "UPDATE_ENTRY":
			return state.map((form) =>
				form.id === action.payload.id ? { ...form, values: { ...form.values, ...action.payload.values } } : form
			);
		case "DELETE_ENTRY":
			return state.filter((form) => form.id !== action.payload.id);
		case "RESET":
			return [];
		default:
			return state;
	}
}

export default function AddItemPage() {
	const barcodeRef = useRef(null);
	const formRefs = useRef([]);
	const valueRefs = useRef([]);
	const [formArray, dispatch] = useReducer(formArrayReducer, []);

	const { themeObj } = useTheme();
	const { store } = useAuth();

	const addItem = (data) => {
		const id = Math.random().toString(36).substring(7);
		dispatch({ type: "ADD_ENTRY", payload: { id, values: { barcode_id: data?.value } } });
	}

	const deleteItem = (id) => {
		dispatch({ type: "DELETE_ENTRY", payload: { id } });
		formRefs.current = formRefs.current.filter(item => item.id !== id);
		valueRefs.current = valueRefs.current.filter(item => item.id !== id);
	}

	const onFormSubmit = (id, values) => {
		formRefs.current = formRefs.current?.map(item => item.id === id ? { ...item, values } : item);
		console.log(id, values)
	}

	const handleSubmit = async () => {
		Object.values(formRefs.current).forEach((ref) => {
			ref.values = null;
		});
		formRefs.current.forEach(async (ref) => { await ref.form?.requestSubmit() });

		setTimeout(async () => {
			const data = formRefs.current.map(ref => ref.values).filter(e => e);

			if (data.length !== formArray.length) {
				toast.error("Some item details are missing.");
				return;
			}

			const response = await addItems(data.map(item => ({ ...item, store_id: store.store_id })));

			if (!response.success) {
				toast.error(response.message);
				return;
			}
			toast.success("Items Added Successfully");
			dispatch({ type: "RESET" });
			formRefs.current = [];
			valueRefs.current = [];
		}, 500);

		// setTimeout(async () => {
		// 	var flag = false;
		// 	var error = []

		// 	if (formArray.length === 0) {
		// 		itemsTableRef.current.show();
		// 		error = "Item List cannot be empty.";
		// 		flag = true;
		// 	}
		// 	else if (!formArray.every(i => i)) {
		// 		itemsTableRef.current.show();
		// 		error = "Some formArray details are missing.";
		// 		flag = true;
		// 	}
		// 	if (flag) {
		// 		console.log("some error");
		// 		toast.error(error);
		// 		return;
		// 	}

		// 	// const transactionsObj = {
		// 	// 	store_id: store.store_id,
		// 	// 	transaction_type: "sale",
		// 	// 	...transactionDetails.current,
		// 	// 	formArray,
		// 	// 	payments: { ...paymentDetails.current },
		// 	// 	seller_details: {
		// 	// 		uid: mongoUser._id,
		// 	// 		store_gstin: store.store_gstin,
		// 	// 		store_name: store.store_name,
		// 	// 		name: mongoUser.name,
		// 	// 		phone: mongoUser.phone,
		// 	// 		email: mongoUser.email,
		// 	// 	},
		// 	// 	customer_details: { ...customerDetails.current },
		// 	// 	status: paymentDetails.current.status
		// 	// }

		// 	// console.log(transactionsObj);
		// 	// const response = await createSale(transactionsObj);
		// 	// if (!response.success) {
		// 	// 	toast.error(JSON.stringify(response.message));
		// 	// 	return;
		// 	// }
		// 	// toast.success("Transaction successful");
		// }, 500) // add delay, or else it doesnt reflect value for some reason aaaaaa
	}

	return (
		<Stack gap={1}>
			<Stack sx={{ mb: 2 }} gap={2}>
				<BarcodeScanner ref={barcodeRef} onResult={addItem} disableApi={true} />
				<Divider variant="middle" sx={{ "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.subtitle} fontSize="0.7rem">OR</Typography>
				</Divider>
				<Button
					onClick={addItem}
					sx={{
						marginBottom: "10px",
						backgroundColor: themeObj.primary,
						color: themeObj.title,
						'&:hover': {
							backgroundColor: themeObj.primary_hover,
						}
					}}
				>
					Add an Item
				</Button>
			</Stack>
			{formArray.map(form =>
				<AddItemForm
					key={form.id}
					ref={(el) => {
						if (el) {
							formRefs.current = formRefs.current.filter(item => item.id !== form.id);
							formRefs.current.push({ id: form.id, form: el });
						}
					}}
					onFormSubmit={(values) => onFormSubmit(form.id, values)}
					deleteItem={() => deleteItem(form.id)}
					data={form.values}
				/>
			)}
			<Button
				onClick={handleSubmit}
				disabled={formArray.length === 0}
				sx={{
					marginBottom: "10px",
					backgroundColor: themeObj.primary,
					color: themeObj.title,
					'&:hover': {
						backgroundColor: themeObj.primary_hover,
					},
					'&:disabled': {
						backgroundColor: themeObj.secondary,
						color: themeObj.subtitle,
					}
				}}
			>
				Add Items
			</Button>
		</Stack>
	)
}