/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useReducer } from "react";
import { IconButton, Stack, Box, Typography, Paper, CircularProgress, Chip, Button, TextField, MenuItem, Grid, Divider, Collapse } from "@mui/material";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useTheme } from "../../context/ThemeContext";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TagsEdit from "../../components/Item/TagsEdit";
import { updateItem } from "../../apis/itemApi";
import { useNavigate } from "react-router-dom";
import VendorDetailEdit from "../../components/Vendor/VendorDetailEdit";
import VendorDetailField from "../../components/Vendor/VendorDetailField";
import ItemItem from "../../components/Vendor/ItemItem";
import Cart from "../../components/Vendor/Cart";
import { TransitionGroup } from "react-transition-group";

const validationSchema = Yup.object({
	name: Yup.string().required('Name is required'),
	phone: Yup.string().required('Phone is required'),
	gstin: Yup.string().required('GSTIN is required'),
});

const cartReducer = (state, action) => {
	switch (action.type) {
		case 'add':
			const existingItem = state.find(item => item.item_id === action.item.item_id);
			const { sales_metrics, ...itemData } = action.item;
			if (existingItem) {
				return state.map(item =>
					item.item_id === action.item.item_id
						? { ...item, quantity: item.quantity + 1, total_price: item.total_price + item.selling_price }
						: item
				);
			} else {
				return [...state, { ...itemData, quantity: 1, total_price: action.item.selling_price }];
			}
		case 'remove':
			const existingItemToRemove = state.find(item => item.item_id === action.item.item_id);
			if (existingItemToRemove.quantity === 1) {
				return state.filter(item => item.item_id !== action.item.item_id);
			}
			return state.map(item =>
				item.item_id === action.item.item_id
					? { ...item, quantity: item.quantity - 1, total_price: item.total_price - item.selling_price }
					: item
			);
		case 'delete':
			return state.filter(item => item.item_id !== action.item.item_id);
		case 'clear':
			return [];
		default:
			return state;
	}
}

export default function VendorDetails({ data, editCallback }) {
	const [loaded, setLoaded] = useState(false);
	const { themeObj } = useTheme();
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [cart, dispatch] = useReducer(cartReducer, []);
	const [itemData, setItemData] = useState(data.purchase_history || []);
	const navigate = useNavigate();

	const addToCart = (item) => {
		dispatch({ type: 'add', item });
	}

	const deleteFromCart = (item) => {
		dispatch({ type: 'delete', item });
	}

	const removeSingleItem = (item) => {
		dispatch({ type: 'remove', item });
	}

	const clearCart = () => {
		dispatch({ type: 'clear' });
	}

	const checkout = () => {
		navigate("/add-purchase", {
			state: {
				from: 'vendors',
				data: {
					vendor: data,
					items: cart
				}
			}
		});
	}

	const categories = ['kilograms', 'litres', 'pieces', 'units']

	const formik = useFormik({
		initialValues: {
			name: data.name ?? '',
			phone: data.phone ?? '',
			email: data.email ?? '',
			address: data.address ?? '',
			gstin: data.gstin ?? '',
			type: data.type ?? '',
			tags: data.tags ?? [],
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsSaving(true);
				const response = await updateItem(data.item_id, values);
				if (response.success) {
					setIsEditing(false);
					editCallback(values);
					// Optionally refresh data or show success message
				} else {
					// Handle error
					console.error('Failed to update item');
				}
			} catch (error) {
				console.error('Error updating item:', error);
			} finally {
				setIsSaving(false);
			}
		},
	});

	const renderField = (label, field) => {
		if (isEditing) {
			return (
				<VendorDetailEdit
					heading={label}
					name={field}
					placeholder={label}
					value={formik.values[field]}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched[field] && Boolean(formik.errors[field])}
					helperText={formik.touched[field] && formik.errors[field]}
				/>
			);
		}
		return <VendorDetailField label={label} value={data[field]} />;
	};

	return (
		<Stack>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<IconButton onClick={() => navigate('/vendors')}>
								<KeyboardBackspaceRoundedIcon htmlColor={themeObj.primary} />
							</IconButton>
							{!isEditing ? (
								<IconButton onClick={() => setIsEditing(true)}>
									<EditIcon htmlColor={themeObj.primary} />
								</IconButton>
							) : (
								<Stack direction="row" spacing={0.5}>
									<IconButton onClick={() => setIsEditing(false)}>
										<CancelIcon htmlColor={themeObj.primary} />
									</IconButton>
									<IconButton type="submit" disabled={isSaving}>
										{isSaving ? <CircularProgress size={24} /> : <SaveRoundedIcon htmlColor={themeObj.primary} />}
									</IconButton>
								</Stack>
							)}
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: isEditing ? themeObj.card : themeObj.secondary }}>
							{isEditing ? (
								<>
									<TextField
										size="small"
										multiline
										fullWidth
										heading="Item Name"
										name="name"
										placeholder="Item Name*"
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.name && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
										InputProps={{
											sx: {
												fontSize: '1.3rem',
												color: themeObj.contrast,
												'& fieldset': {
													border: "none",
												},
												p: 0
											},
										}}
									/>
									<TagsEdit
										data={formik.values.tags}
										setVal={(val) => formik.setFieldValue('tags', val)}
									/>
									<TextField
										size="small"
										fullWidth
										name="type"
										placeholder="Category*"
										value={formik.values.type}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.type && Boolean(formik.errors.type)}
										helperText={formik.touched.type && formik.errors.type}
										InputProps={{
											sx: {
												fontWeight: 500,
												fontSize: '0.8rem',
												color: themeObj.contrast,
												'& fieldset': {
													border: "none",
												},
												'& input': {
													p: 0
												}
											},
										}}
									/>
								</>
							) : (
								<>
									<Typography color={themeObj.title} fontWeight={500} fontSize="1.3rem">
										{data.name}
									</Typography>
									<Stack flexDirection="row" gap={0.5} sx={{ my: 0.5 }}>
										{data.tags?.map((t, i) => (
											<Chip
												variant="outlined"
												size="small"
												color="warning"
												key={i}
												label={t}
											/>
										))}
									</Stack>
									<Typography sx={{ textTransform: "capitalize" }} color={themeObj.title} fontWeight={500} fontSize="0.8rem">
										{data.type}
									</Typography>
								</>
							)}
						</Paper>
					</Grid>

					<Grid item xs>
						{renderField("Phone", "phone")}
					</Grid>
					<Grid item xs>
						{renderField("GSTIN", "gstin")}
					</Grid>
					<Grid item xs={12}>
						{renderField("Email", "email")}
					</Grid>
					<Grid item xs={12}>
						{isEditing ?
							<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: isEditing ? themeObj.card : themeObj.secondary }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">Address:</Typography>
								<TextField
									size="small"
									variant="standard"
									fullWidth
									multiline
									name="address"
									value={formik.values.address}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.address && Boolean(formik.errors.address)}
									helperText={formik.touched.address && formik.errors.address}
									InputProps={{
										sx: {
											fontSize: '1rem',
											py: 0,
											color: themeObj.contrast,
											'&::before': {
												borderBottomColor: themeObj.subtitle
											},
											'&::after': {
												borderBottomColor: themeObj.primary
											},
											'& input': {
												py: 0
											}
										},
									}}
								/>
							</Paper>

							:
							<VendorDetailField label="Address" value={data.address} />
						}
					</Grid>
				</Grid>
			</form>
			<Stack>
				<Divider variant="middle" sx={{ marginBottom: '5px', "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.title} fontSize="1.2rem">Items</Typography>
				</Divider>
				<TransitionGroup>
					{itemData?.length !== 0 ?
						itemData?.map(e => (
							<Collapse key={e.item_id}>
								<ItemItem {...e} addToCart={() => { addToCart(e) }} />
							</Collapse>
						))
						:
						<Typography textAlign="center" variant="h5" style={{ marginTop: '20px' }}>Data Not Found</Typography>

					}
				</TransitionGroup>
			</Stack>
			<Cart checkout={checkout} makeTransaction={() => { }} cart={cart} addItem={addToCart} deleteFromCart={deleteFromCart} removeSingleItem={removeSingleItem} clearCart={clearCart} />
			<div style={{ height: 10 }} />
		</Stack>
	);
}