import apiInstance from './apiInstance';

async function createUser(userData = {}) {
	try {
		const response = await apiInstance.post('/users/create_user/', userData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to create user."
		};
	}
}

async function viewUser() {
	try {
		const response = await apiInstance.get('/users/view_user/');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view user."
		};
	}
}

async function updateUser(updatedData = {}) {
	try {
		const response = await apiInstance.put('/users/update_user/', updatedData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to update user."
		};
	}
}

async function delinkStore() {
	try {
		const response = await apiInstance.post('/users/delink_store/');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to delink store."
		};
	}
}

async function deleteUser() {
	try {
		const response = await apiInstance.delete('/users/delete_user/');
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to delete user."
		};
	}
}

export {
	createUser,
	viewUser,
	updateUser,
	delinkStore,
	deleteUser,
};