import React, { useState, useEffect, createContext, useContext } from "react";
import { auth } from "../services/firebase";
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo, signOut as so } from "firebase/auth";
import { viewUser } from "../apis/userApi";
import { viewStore } from "../apis/storeApi";
import useLocalStorage from "../hooks/useLocalStorage";;

const AuthContext = createContext({ user: null });

export const AuthProvider = (props) => {
	const [user, setUser] = useState(null);
	const [mongoUser, mongoUserDispatch] = useLocalStorage('user');
	const [store, storeDispatch] = useLocalStorage('store');
	const [accessToken, accessTokenDispatch] = useLocalStorage('accessToken');
	const [storeLoaded, setStoreLoaded] = useState(false);
	const [userLoaded, setUserLoaded] = useState(false);
	const [mongoUserLoaded, setMongoUserLoaded] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const signIn = async () => {
		const provider = new GoogleAuthProvider();
		const res = await signInWithPopup(auth, provider);
	}
	const signOut = () => {
		console.log('here32');
		mongoUserDispatch({ type: "REMOVE" })
		storeDispatch({ type: "REMOVE" })
		accessTokenDispatch({ type: "REMOVE" })
		setUser(null);
		return so(auth);
	}

	const refresh = () => {
		setMongoUserLoaded(false);
		setStoreLoaded(false);

		if(mongoUser && store) {
			// bypass this
			setMongoUserLoaded(true);
			setStoreLoaded(true);
			return;
		}

		const getMongoUser = async () => {
			const response = await viewUser(user?.uid);
			if (response.success) mongoUserDispatch({ type: "SET", payload: response.data });
			console.log("User set");
			setMongoUserLoaded(true);
		}

		const getStore = async () => {
			const response = await viewStore(user?.uid);
			if (response.success) storeDispatch({ type: "SET", payload: response.data });
			console.log("Store set");
			setStoreLoaded(true);
		}

		getMongoUser();
		getStore();

	}

	const updateStoreLocal = (data) => {
		storeDispatch({ type: "SET", payload: data });
	}

	const updateUserLocal = (data) => {
		mongoUserDispatch({ type: "SET", payload: data });
	}

	useEffect(() => {
		auth.onAuthStateChanged(async (user) => {
			setUserLoaded(false);
			if (user) {
				setUserLoaded(true);
				accessTokenDispatch({ type: "SET", payload: await user.getIdToken() });
			}
			else {
				setLoaded(true);
			}
			setUser(user);
		});
	}, []);

	useEffect(() => {
		if (userLoaded && storeLoaded && mongoUserLoaded) {
			setLoaded(true);
		}
	}, [userLoaded, storeLoaded, mongoUserLoaded]);

	// useEffect(() => {
	// 	refresh();
	// })

	const value = {
		user,
		mongoUser,
		store,
		signIn,
		signOut,
		refresh,
		updateStoreLocal,
		updateUserLocal,
		loaded
	}

	return (
		<AuthContext.Provider value={value}>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};