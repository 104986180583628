import apiInstance from './apiInstance';

const viewVendors = async (options = {}) => {
	const params = {
		page: options.page || 1,
		page_size: options.pageSize || 10
	};

	try {
		const response = await apiInstance.get('/customer_vendor/vendors_item_data', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view vendors."
		};
	}
};

const viewCustomers = async (options = {}) => {
	const params = {
		page: options.page || 1,
		page_size: options.pageSize || 10
	};

	if (options.query) params.query = options.query;

	try {
		const response = await apiInstance.get('/customer_vendor/search_customers', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view customers."
		};
	}
};

const searchVendors = async (query) => {
	const params = {
		query: query
	};

	try {
		const response = await apiInstance.get('/customer_vendor/search_vendors', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to search vendors."
		};
	}
};

export { viewVendors, viewCustomers, searchVendors };