import { Stack, Grid, TextField, Paper, Typography, MenuItem, InputAdornment, Box, IconButton, Collapse } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "../../context/ThemeContext";
import { DeleteRounded, ExpandMoreRounded } from "@mui/icons-material";

const validationSchema = Yup.object({
	item_name: Yup.string().required('Item Name is required'),
	cost_price: Yup.number().typeError('Cost Price must be a number'),
	selling_price: Yup.number().typeError('Selling Price must be a number'),
	understock_qt: Yup.number().typeError('Understock Quantity must be a number'),
	overstock_qt: Yup.number().typeError('Overstock Quantity must be a number'),
	quantity: Yup.number().typeError('Quantity must be a number'),
});

const AddItemForm = forwardRef(({ onFormSubmit, data, deleteItem }, ref) => {
	const { themeObj } = useTheme();
	const [expanded, setExpanded] = useState(true);

	const formik = useFormik({
		initialValues: {
			item_name: data?.item_name ?? "",
			barcode_id: data?.barcode_id ?? "",
			hsn_sac_code: data?.hsn_sac_code ?? "",
			item_description: data?.item_description ?? "",
			item_category: data?.item_category ?? "",
			item_tags: data?.item_tags ?? [],
			qt_type: data?.qt_type ?? "pieces",
			cost_price: data?.cost_price ?? 0.0,
			selling_price: data?.selling_price ?? 0.0,
			understock_qt: data?.understock_qt ?? 5,
			overstock_qt: data?.overstock_qt ?? 40,
			quantity: data?.quantity ?? 0,
			image_url: data?.image_url ?? "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit({
				...values,
				cost_price: parseFloat(values.cost_price),
				selling_price: parseFloat(values.selling_price),
				understock_qt: parseFloat(values.understock_qt),
				overstock_qt: parseFloat(values.overstock_qt),
				quantity: parseFloat(values.quantity),
			});
		},
	});

	useImperativeHandle(ref, () => ({
		hide: () => {
			setExpanded(false)
		},
		show: () => {
			setExpanded(true);
		},
		requestSubmit: async () => {
			if (Object.keys(formik.errors).length) {
				setExpanded(true);
			}
			else {
				setExpanded(false);
				formik.submitForm();
			}
		}
	}));

	useEffect(() => {
		if (Object.keys(formik.errors).length && !expanded) {
			setExpanded(true);
		}
	}, [formik.errors]);

	return (
		<Box>
			<Paper elevation={4} sx={{ width: '100%', padding: '5px 10px', borderRadius: 1, backgroundColor: themeObj.secondary, height: '60px', display: "flex", alignItems: "center" }} onClick={() => setExpanded(!expanded)}>
				<Stack alignItems='center' flexDirection="row" justifyContent="space-between" width="100%">
					<Typography textAlign="left" color={themeObj.title} fontSize="1.1rem" >{formik.values.item_name || "New Item"}</Typography>
					<Stack flexDirection="row" alignItems='center'>
						<IconButton onClick={(e) => { e.stopPropagation(); deleteItem() }}>
							<DeleteRounded htmlColor={themeObj.chip.red} />
						</IconButton>
						<IconButton >
							<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.title} />
						</IconButton>
					</Stack>
				</Stack>
			</Paper>
			<Collapse in={expanded} sx={{ marginTop: '5px' }}>
				<form
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ flexGrow: 1, margin: 'auto' }}
				>
					<Paper sx={{ borderRadius: 1, padding: "10px", backgroundColor: themeObj.card }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									id="item_name"
									name="item_name"
									label="Item Name*"
									value={formik.values.item_name}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_name && Boolean(formik.errors.item_name)}
									helperText={formik.touched.item_name && formik.errors.item_name}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									id="item_description"
									name="item_description"
									label="Item Description"
									value={formik.values.item_description}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_description && Boolean(formik.errors.item_description)}
									helperText={formik.touched.item_description && formik.errors.item_description}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="barcode_id"
									name="barcode_id"
									label="Barcode ID"
									value={formik.values.barcode_id}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.barcode_id && Boolean(formik.errors.barcode_id)}
									helperText={formik.touched.barcode_id && formik.errors.barcode_id}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="hsn_sac_code"
									name="hsn_sac_code"
									label="HSN SAC Code"
									value={formik.values.hsn_sac_code}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.hsn_sac_code && Boolean(formik.errors.hsn_sac_code)}
									helperText={formik.touched.hsn_sac_code && formik.errors.hsn_sac_code}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="cost_price"
									name="cost_price"
									label="Cost Price"
									value={formik.values.cost_price}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.cost_price && Boolean(formik.errors.cost_price)}
									helperText={formik.touched.cost_price && formik.errors.cost_price}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="selling_price"
									name="selling_price"
									label="Selling Price"
									value={formik.values.selling_price}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.selling_price && Boolean(formik.errors.selling_price)}
									helperText={formik.touched.selling_price && formik.errors.selling_price}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="understock_qt"
									name="understock_qt"
									label="Understock Quantity"
									value={formik.values.understock_qt}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.understock_qt && Boolean(formik.errors.understock_qt)}
									helperText={formik.touched.understock_qt && formik.errors.understock_qt}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="overstock_qt"
									name="overstock_qt"
									label="Overstock Quantity"
									value={formik.values.overstock_qt}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.overstock_qt && Boolean(formik.errors.overstock_qt)}
									helperText={formik.touched.overstock_qt && formik.errors.overstock_qt}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									select
									size="small"
									fullWidth
									id="item_category"
									name="item_category"
									label="Item Category"
									value={formik.values.item_category}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_category && Boolean(formik.errors.item_category)}
									helperText={formik.touched.item_category && formik.errors.item_category}
									sx={{
										fontSize: "0.8rem",
										color: themeObj.title,
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										},
										'& .MuiSelect-icon': {
											color: themeObj.title
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
									SelectProps={{
										MenuProps: {
											sx: {
												'& .MuiMenu-paper': {
													backgroundColor: themeObj.card,
												},
												'& .MuiMenu-list': {
													p: 0
												}
											}
										}
									}}
								>
									<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="electronics">Electronics</MenuItem>
									<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="somecategory">Some Category</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={6}>
								<TextField
									select
									size="small"
									fullWidth
									id="item_tags"
									name="item_tags"
									label="Item Tags"
									value={formik.values.item_tags}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_tags && Boolean(formik.errors.item_tags)}
									helperText={formik.touched.item_tags && formik.errors.item_tags}
									sx={{
										fontSize: "0.8rem",
										color: themeObj.title,
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										},
										'& .MuiSelect-icon': {
											color: themeObj.title
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
									SelectProps={{
										multiple: true,
										MenuProps: {
											sx: {
												'& .MuiMenu-paper': {
													backgroundColor: themeObj.card,
												},
												'& .MuiMenu-list': {
													p: 0
												}
											}
										}
									}}
								>
									<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="tag1">Tag1</MenuItem>
									<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="tag2">Tag2</MenuItem>
								</TextField>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</Collapse>
		</Box >
	)
});

export default AddItemForm;