import React, { useState, useEffect } from "react";
import TicketSearch from "./TicketSearch";
import { viewTickets } from "../../apis/ticketApi";
import TicketInterface from "./TicketInterface";

const PAGESIZE = 30;

export default function TicketsPage() {
	const [state, setState] = useState('search'); // "search" or "ticket"
	const [ticket, setTicket] = useState({});

	const [renderedData, setRenderedData] = useState([]);
	const [loaded, setLoaded] = useState(true);

	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const [noData, setNoData] = useState(false);

	const onClickHandler = (ticket) => {
		setTicket(ticket);
		// setState('transaction');
		window.scrollTo(0, 0);
	}

	const updateTicketStatus = (ticket_id, status) => {
		const newRenderedData = renderedData.map(e => {
			if (e.ticket_id === ticket_id) {
				e.ticket_status = status;
			}
			return e;
		});
		setRenderedData(newRenderedData);
	}

	const reload = () => {
		setLoaded(false);
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
			};
			const response = await viewTickets(options)
			if (response.success) {
				const data = response.data;
				console.log(data);
				setRenderedData(data.tickets);
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		getData();
	}

	const pageSearch = (page) => {
		const getData = async () => {
			const options = {
				page: page,
				pageSize: PAGESIZE,
			};
			const response = await viewTickets(options)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
				setRenderedData(data.transactions);
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		setLoaded(false);
		// getData();
		window.scrollTo(0, 0);
	}

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
			};
			const response = await viewTickets(options)
			if (response.success) {
				const data = response.data;
				console.log(data);
				setRenderedData(data.tickets);
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		getData();
	}, [])

	// useEffect(() => {
	// 	if (loaded) {
	// 		console.log(page)
	// 		setLoaded(false);
	// 		pageSearch(page);
	// 	}
	// }, [page]);

	return state === "search" ?
		<TicketSearch
			loaded={loaded}
			page={page}
			setPage={setPage}
			renderedData={renderedData}
			hasNext={hasNext}
			onClick={(e) => onClickHandler(e)}
			noData={noData}
			updateTicketStatus={updateTicketStatus}
			reload={reload}
		/>
		:
		<TicketInterface
			data={ticket}
			onBack={() => { setTicket({}); setState('search') }}
		/>
		;
}