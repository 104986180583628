import apiInstance from './apiInstance';

async function viewInventory(options) {
	const params = {
		page: options.page || 1,
		page_size: options.pageSize || 30,
	};
	if (options.barcodeId) params.barcode_id = options.barcodeId;
	if (options.itemId) params.item_id = options.itemId;
	if (options.itemCategory) params.item_category = options.itemCategory;
	if (options.overstock !== undefined) params.overstock = options.overstock;
	if (options.understock !== undefined) params.understock = options.understock;
	if (options.sortBy) params.sort_by = options.sortBy;
	if (options.sortOrder) params.sort_order = options.sortOrder;

	try {
		const response = await apiInstance.get('/stocks/view_inventory', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch inventory."
		};
	}
}

async function viewInventoryBySales(options) {
	const params = {
		last_n_days: options.lastNDays || 7,
		page: options.page || 1,
		page_size: options.pageSize || 30,
	};

	try {
		const response = await apiInstance.get('/stocks/view_inventory_by_sales', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch inventory by sales."
		};
	}
}

async function viewSingleItem(options) {
	const params = {
		item_id: options.itemId
	};
	if (options.lastNDays) params.last_n_days = options.lastNDays;
	if (options.lastNTransactions) params.last_n_transactions = options.lastNTransactions;

	try {
		const response = await apiInstance.get('/stocks/view_single_item', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to fetch single item."
		};
	}
}

export { viewInventory, viewInventoryBySales, viewSingleItem };