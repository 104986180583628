import React, { useState } from "react";
import { Collapse, Typography, Box, Button, Stack, DialogActions, DialogContent, DialogTitle, Dialog, TextField, MenuItem } from "@mui/material";
import Pagination from "../../components/Common/Pagination";
import { TransitionGroup } from "react-transition-group";
import TransactionItemLoader from "../../components/Transaction/TransactionItemLoader";

import { useTheme } from "../../context/ThemeContext";
import TicketItem from "../../components/Tickets/TicketItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createTicket } from "../../apis/ticketApi";

const validationSchema = Yup.object({
	ticket_description: Yup.string().required('Description is required'),
	ticket_category: Yup.string().required('Category is required')
});

export default function TicketSearch({ loaded, page, setPage, renderedData, hasNext, onClick, noData, updateTicketStatus, reload }) {
	const { themeObj } = useTheme();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const categories = [
		{ value: 'billing', label: 'Billing' },
		{ value: 'technical', label: 'Technical' },
		{ value: 'other', label: 'Other' },
	]

	const close = () => {
		setOpen(false);
		formik.resetForm();
	};

	const submitForm = async (values) => {
		const response = await createTicket({
			ticket_category: values.ticket_category,
			ticket_description: values.ticket_description
		});
		if (response.success) {
			close();
			setLoading(false);
			reload();
		}
	}

	const formik = useFormik({
		initialValues: {
			ticket_description: '',
			ticket_category: 'billing'
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			setLoading(true);
			submitForm(values);
		}
	});

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
			<Stack gap={2}>
				<Dialog
					open={open}
					onClose={close}
					fullWidth
					PaperProps={{
						sx: {
							backgroundColor: themeObj.secondary,
						}
					}}
				>
					<form
						component="form"
						onSubmit={formik.handleSubmit}
					>
						<DialogTitle color={themeObj.title}>
							File Issue
						</DialogTitle>
						<DialogContent>
							<Stack gap={2} mt={1}>
								<TextField
									fullWidth
									multiline
									rows={3}
									id="ticket_description"
									name="ticket_description"
									label="Ticket Description"
									value={formik.values.ticket_description ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.ticket_description && Boolean(formik.errors.ticket_description)}
									helperText={formik.touched.ticket_description && formik.errors.ticket_description}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
								/>
								<TextField
									select
									fullWidth
									id="ticket_category"
									name="ticket_category"
									label="Ticket Category"
									value={formik.values.ticket_category}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.ticket_category && Boolean(formik.errors.ticket_category)}
									helperText={formik.touched.ticket_category && formik.errors.ticket_category}
									sx={{
										fontSize: "0.8rem",
										color: themeObj.title,
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: themeObj.primary,
											},
											'& fieldset': {
												borderColor: themeObj.subtitle,
											},
										},
										'& .MuiSelect-icon': {
											color: themeObj.title
										}
									}}
									InputLabelProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title,
											'&.Mui-focused': {
												color: themeObj.primary
											},
										}
									}}
									InputProps={{
										sx: {
											fontSize: '1rem',
											color: themeObj.title
										},
									}}
									SelectProps={{
										MenuProps: {
											sx: {
												'& .MuiMenu-paper': {
													backgroundColor: themeObj.card,
												},
												'& .MuiMenu-list': {
													p: 0
												}
											}
										}
									}}
								>
									{categories.map((option) => <MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} key={option.value} value={option.value}>{option.label}</MenuItem>)}
								</TextField>
							</Stack>
						</DialogContent>
						<DialogActions>
							<Button
								fullWidth
								type="submit"
								disabled={loading}
								sx={{
									backgroundColor: themeObj.primary,
									color: themeObj.title,
									"&:hover": {
										backgroundColor: themeObj.primary_hover,
									}
								}}
							>
								File Issue
							</Button>
						</DialogActions>
					</form>
				</Dialog>
				<Button
					fullWidth
					onClick={() => setOpen(true)}
					sx={{
						backgroundColor: themeObj.primary,
						color: themeObj.title,
						p: 1.2,
						"&:hover": {
							backgroundColor: themeObj.primary_hover,
						}
					}}
				>
					File Issue
				</Button>
				<TransitionGroup>
					{loaded ?
						(renderedData?.length !== 0 &&
							renderedData.map(e => (
								<Collapse key={e.ticket_id}>
									<TicketItem {...e} onClick={() => onClick(e)} updateTicketStatus={updateTicketStatus} />
								</Collapse>
							))
						)
						:
						Array.from({ length: 20 }, (e, i) =>
							<Collapse key={i} sx={{ marginBottom: '12px' }}>
								<TransactionItemLoader />
							</Collapse>
						)
					}
				</TransitionGroup>
			</Stack>
			{(noData || (loaded && renderedData.length === 0)) && (
				<Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "center", width: "100%", my: 5 }}>
					<Typography variant="h5" sx={{ color: themeObj.title }}>No Data <span style={{ color: themeObj.primary, fontWeight: 600 }}>!</span></Typography>
				</Box>
			)}
			{!noData &&
				<Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
					<Pagination
						page={page}
						setPage={setPage}
						hasNext={hasNext}
					/>
				</Box>
			}
		</div>
	);
}