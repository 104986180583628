import apiInstance from './apiInstance';

async function viewTickets(options = {}) {
	const params = {
		page: options.page || 1
	};

	try {
		const response = await apiInstance.get('/tickets/view_tickets', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view tickets."
		};
	}
}

async function createTicket(ticketData) {
	try {
		const response = await apiInstance.post('/tickets/create_ticket', ticketData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to create ticket."
		};
	}
}

async function updateTicket(ticketId, ticketData) {
	try {
		const response = await apiInstance.put(`/tickets/update_ticket/${ticketId}`, ticketData);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to update ticket."
		};
	}
}

async function deleteTicket(ticketId) {
	try {
		const response = await apiInstance.delete(`/tickets/delete_ticket/${ticketId}`);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to delete ticket."
		};
	}
}

async function addMessage(ticketId, message) {
	try {
		const response = await apiInstance.post(`/tickets/add_message/${ticketId}`, {
			content: message
		});
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to add message."
		};
	}
}

export { viewTickets, createTicket, updateTicket, deleteTicket, addMessage };