import api from './apiInstance'; // Assuming you have an apiInstance.js file

async function searchAudio(base64Audio) {
	try {
		const response = await api.post('/audios/search_audio/', {
			base64: base64Audio
		});
		return { success: true, data: response.data };
	} catch (error) {
		return { 
			success: false, 
			message: error.response?.data?.message || "Network error, unable to search audio."
		};
	}
}

export { searchAudio };
