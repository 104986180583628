import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { IconButton, Box, Typography } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import LanguageSelector from './LanguageSelector';
import UserDetails from './UserDetails';
import StoreSelect from './StoreSelect';
import { useAuth } from '../../context/AuthContext';
import { createUser } from '../../apis/userApi';
import { useLocale } from '../../context/LocaleContext';
import krayaLogoWhite from '../../assets/krayaLogoWhite.jpg'
import { useNavigate } from 'react-router-dom';

const pageVariants = {
	initial: (direction) => ({
		x: direction > 0 ? '100%' : '-100%', // Start off-screen to the right or left
		opacity: 0,
	}),
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			opacity: { duration: 0.3 },
			x: { duration: 0.3 },
		},
	},
	exit: (direction) => ({
		x: direction > 0 ? '-100%' : '100%', // Exit to the left or right
		opacity: 0,
		transition: {
			opacity: { duration: 0.3 },
			x: { duration: 0.3 },
		},
	}),
};

const OnboardingPage = () => {
	const [page, setPage] = useState(0);
	const [locale, setLocale] = useState('en')
	const [direction, setDirection] = useState(0); // New state to track direction
	const [processing, setProcessing] = useState(false)
	const [success, setSuccess] = useState(true);
	const { themeObj } = useTheme();
	const userFormRef = useRef(null);
	const userDetails = useRef(null);
	const { user, mongoUser, store, refresh } = useAuth();
	const { messages } = useLocale();
	const navigate = useNavigate();

	const handleNext = async () => {
		if (page < 2) {
			if (page === 0) {
				await userFormRef.current.requestSubmit();
				if (userDetails.current && Object.values(userDetails.current).length) {
					setPage(page + 1);
					setDirection(1);
					setProcessing(true);
					const postData = async () => {
						const userData = {
							...userDetails.current,
							email: user.email,
							picture: user.photoURL,
							email_verified: true,
							user_metadata: {
								language: locale,
							}
						}
						const response = await createUser(userData);
						setProcessing(false);
						if (response.success) {
							console.log(userData);
							setSuccess(true);
							refresh();
						}
						else {
							console.error("Error creating user:", response.message);
							setSuccess(false);
						}
					}
					postData();
					console.log("User details:", userDetails.current);
				}
			}
			else {
				setDirection(1);
				setPage(page + 1);
			}
		}
	};

	const handleBack = () => {
		if (page > 0) {
			setDirection(-1);
			setPage(page - 1);
		}
	};

	const submitForm = (data) => {
		userDetails.current = data;
	}

	useEffect(() => {
		if (mongoUser) setPage(1);
		if (store) {
			navigate('/dashboard');
		}
	}, [mongoUser, store]);

	return (
		<Box sx={{ position: "relative", display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: themeObj.background, overflowX: "hidden", color: themeObj.white }}>
			<Box component='main' sx={{ flexGrow: 1, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", overflow: "hidden" }}>
				<AnimatePresence initial={false} custom={direction}>
					<motion.div
						key={page}
						custom={direction}
						variants={pageVariants}
						initial="initial"
						animate="enter"
						exit="exit"
						style={{ width: "100%", height: "100%", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", padding: '50px' }}
					>
						{/* {page === 0 && (
							<LanguageSelector locale={locale} setLocale={setLocale} />
						)} */}

						{page === 0 && (
							<Box sx={{ width: "100%" }}>
								<motion.div
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.5, delay: 0.3 }}
								>
									<Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem', flexDirection: "column" }}>
										<img src={krayaLogoWhite} width={100} alt="Kraya Logo" style={{ borderRadius: '50%' }} />
										<Typography gutterBottom sx={{ color: themeObj.title }} variant="h4">
											{messages.onboarding.welcome}
										</Typography>
									</Box>
								</motion.div>
								<motion.div
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.5, delay: 0.8 }}
								>
									<Typography sx={{ color: themeObj.subtitle }} textAlign="center">
										{messages.onboarding.details}
									</Typography>
									<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }} ></Box>
									<UserDetails handleNext={handleNext} ref={userFormRef} onFormSubmit={submitForm} initial={userDetails.current} />
								</motion.div>
							</Box>
						)}
						{page === 1 && (
							<StoreSelect success={success} processing={processing} />
						)}
					</motion.div>
				</AnimatePresence>
			</Box>
			{/* <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "1rem", position: "absolute", bottom: 0, left: 0 }}>
				<IconButton sx={{ visibility: page === 0 ? 'hidden' : 'visible' }} onClick={handleBack}>
					<ArrowBackIos htmlColor={themeObj.primary} />
				</IconButton>
				<IconButton sx={{ visibility: page === 3 ? 'hidden' : 'visible' }} onClick={handleNext}>
					<ArrowForwardIos htmlColor={themeObj.primary} />
				</IconButton>
			</Box> */}
		</Box>
	);
};

export default OnboardingPage;
