import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VendorSearch from "./VendorSearch";
import data from './sample.json';
import VendorDetails from "./VendorDetails";
import { searchVendors, viewVendors } from "../../apis/vendorCustomerApi";

const PAGESIZE = 30;

export default function VendorPage() {
	const [loaded, setLoaded] = useState(false);
	const [renderedData, setRenderedData] = useState([]);
	const [vendor, setVendor] = useState({});
	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const [state, setState] = useState('search');
	const [noData, setNoData] = useState(false);
	const { vendorId } = useParams();

	const doSearch = async (val) => {
		const response = await searchVendors(val);
		if (response.success) {
			console.log(response.data.data);
			setRenderedData(response.data.data.vendor_details);
			setHasNext(false);
			setState('search');
		} else {
			console.error(response.message);
		}
	}

	useEffect(() => {
		if (vendorId && loaded) {
			let item = renderedData.find(x => x.vendor_id === vendorId);
			if (item) {
				setVendor(item);
				setState('vendor');
			}
		}
		else {
			setState('search');
			setVendor({});
		}
	}, [vendorId, loaded]);

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
			};
			const response = await viewVendors(options)
			if (response.success) {
				const data = response.data;
				console.log(data);
				setRenderedData(data.data.vendor_details);
				setNoData(false);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		getData();
	}, [])

	return state === 'search' ?
		<VendorSearch
			loaded={loaded}
			page={page}
			setPage={setPage}
			renderedData={renderedData}
			hasNext={hasNext}
			doSearch={doSearch}
		/>
		:
		<VendorDetails data={vendor} editCallback={(values) => console.log(values)} />
		;
}